<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="M19.376 12.416 8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
